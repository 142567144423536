var data;

data = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "menuitems.dashboard.text",
    icon: "uil-home-alt",
    badge: {
      variant: "primary",
      text: "menuitems.dashboard.badge",
    },
    link: "/",
  },
  {
    id: 3,
    label: "menuitems.apps.text",
    isTitle: true,
  },
  // {
  //   id: 6,
  //   label: "Properties",
  //   icon: "fas fa-cogs",
  //   link: "/properties",
  // },
  {
    id: 9,
    label: " Landing Page",
    icon: "fas fa-edit",
    link: "/landing-page",
  },
  // {
  //   id: 10,
  //   label: "Corporate",
  //   icon: "fas fa-building",
  //   link: "/corporate",
  // },
  // {
  //   id: 11,
  //   label: "Career",
  //   icon: "fas fa-handshake",
  //   link: "/career",
  // },
  // {
  //   id: 12,
  //   label: "Help Center",
  //   icon: "fas fa-question",
  //   link: "/help-center",
  // },
  // {
  //   id: 13,
  //   label: "Guides",
  //   icon: "fas fa-book-medical",
  //   link: "/guides",
  // },
  // {
  //   id: 15,
  //   label: "Forms",
  //   icon: "mdi mdi-format-float-left",
  //   link: "/forms",
  // },
  // {
  //   id: 16,
  //   label: "Definitions",
  //   icon: "fas fa-cogs",
  //   link: "/definitions",
  // },
  {
    id: 19,
    label: " User",
    icon: "fas fa-users",
    link: "/user",
  },
  {
    id: 20,
    label: " Leads",
    icon: "fas fa-bookmark",
    link: "/lead",
  },
  {
    id: 32,
    label: "CRM Leads",
    icon: "fas fa-bookmark",
    link: "/crm-lead",
  },
  {
    id: 33,
    label: "Campaign Table",
    icon: "fas fa-bookmark",
    link: "/crm-campaign-table",
  },
  {
    id: 34,
    label: "Lead Source Table",
    icon: "fas fa-bookmark",
    link: "/crm-lead-source-table",
  },
  {
    id: 35,
    label: "CRM Lead Medium Table",
    icon: "fas fa-bookmark",
    link: "/crm-lead-medium-table",
  },
  {
    id: 36,
    label: " Whatsapp Code",
    icon: "fab fa-whatsapp",
    link: "/whatsapp-code",
  },
  {
    id: 37,
    label: " Campaign Type",
    icon: "fab fa-google",
    link: "/campaign-type",
  },
  {
    id: 21,
    label: " Home Page",
    icon: "fas fa-home",
    link: "/home-page",
  },
  {
    id: 23,
    label: " Category",
    icon: "fas fa-boxes",
    link: "/category",
  },

  {
    id: 22,
    label: " Language",
    icon: "fas fa-globe",
    link: "/language",
  },

  {
    id: 27,
    label: " Doctors Page",
    icon: "fas fa-hospital-user",
    link: "/doctors-page",
  },
  {
    id: 24,
    label: " Doctors",
    icon: "fas fa-hospital-user",
    link: "/doctors",
  },

  {
    id: 25,
    label: " Highlights",
    icon: "fas fa-highlighter",
    link: "/highlights",
  },
  {
    id: 26,
    label: " Patient Stories",
    icon: "fas fa-user-injured",
    link: "/patient",
  },
  {
    id: 30,
    label: " Patient Stories Page",
    icon: "fas fa-user-injured",
    link: "/patient-page",
  },
  // top on

  {
    id: 28,
    label: " Top Ten",
    icon: "fas fa-arrow-up",
    link: "/top-on",
  },
  {
    id: 29,
    label: " Comment",
    icon: "fas fa-comment",
    link: "/comment",
  },

  // about page
  {
    id: 31,
    label: " About Page",
    icon: "fas fa-info",
    link: "/about-page",
  }, 

  {
    id:38,
    label: " Utm Ad Codes",
    icon: "fas fa-code",
    link: "/utm-ad"
  }
];

export const menuItems = data;
